import React from 'react'
import About from '../../components/about/About'
// import Comments from '../../components/comment/Comments'
import Contact from '../../components/contact/Contact'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import Services from '../../components/services/Services'
import Slider from '../../components/slider/Slider'
import Video from '../../components/video/Video'


function Home() {
    return (
        <div>
            <Header />
            <Slider />
            <Services />
            <About />
            <Video />
            {/* <Comments /> */}
            <Contact />
            <Footer />
        </div>
    )
}

export default Home