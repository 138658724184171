import './ServicesStyle.css'
import LekeImage from '../../images/services/leke-tedavisi.png'
import Dermapen from '../../images/services/dermapen.png'
import IsikDolgusu from '../../images/services/isik-dolgusu.png'
import HairLossImage from '../../images/services/hair-loss.png'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'



function Services() {
    const { t } = useTranslation()
    let navigate = useNavigate()
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-xs-12 col-sm-5 col-md-4'>
                    <div className='services-content-box'>
                        <h2 className='services-first-title'>{t("HomeServicesTitle")}</h2>
                        <h3 className='services-title'>{t("HomeServicesBigTitle")} <br /> <span className='services-title-color'>{t("HomeServicesBigTitle2")}</span> </h3>
                        <p className='services-content'>{t("HomeServicesContent")}</p>
                        {/* <a className='btn btn-button services-button' href='#/'>{t("HomeServicesButton")}</a> */}
                    </div>
                </div>
                <div className='col-xs-12 col-sm-7 col-md-8 services-photo-wrap zero'>
                    <div className='col-xs-12 col-sm-12 zero'>
                        <div className='col-xs-12 col-sm-12 col-md-6 services-photo-box' onClick={() => navigate("/leke-tedavisi")}>
                            <img className='img img-responsive' width={"100%"} src={LekeImage} alt="leke tedavisi" />
                            <span className='services-photo-button'>{t("SpotFirstTitle").toUpperCase()}</span>

                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 services-photo-box' onClick={() => navigate("/dermaterapy")}>
                            <img className='img img-responsive' width={"100%"} src={Dermapen} alt="dermapen" />
                            <span className='services-photo-button'>{t("DermaTerapyFirstTitle").toUpperCase()}</span>


                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-12 zero'>
                        <div className='col-xs-12 col-sm-12 col-md-6 services-photo-box' onClick={() => navigate("/ozon")}>
                            <img className='img img-responsive' width={"100%"} src={IsikDolgusu} alt="isik dolgusu" />
                            <span className='services-photo-button' >{t("NavSerOzon").toUpperCase()}</span>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 services-photo-box' onClick={() => navigate("/sac-dokulme")}>
                            <img className='img img-responsive' width={"100%"} src={HairLossImage} alt="genclik asisi" />
                            <span className='services-photo-button' href='#/'>{t("NavSerHairLoss").toUpperCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services