import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import FillingImage from '../../images/filling/filling.png'

function Filling() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                title={t("FillingFirstTitle")}
                colorTitle={t("BotoxPageSecondTitle")}
                image={FillingImage}
                pone={t("FillingFirstP")}
                ptwo={t("FillingSecondP")}
            />
            <Footer />
        </div>
    )
}

export default Filling