import './VideoStyle.css'

function Video() {
    return (
        <div className='container-fluid zero video-container'>
            <div className='row'>
                <div className='col-xs-12 col-sm-12 video-icon-box'>
                    <a href='https://www.instagram.com/dr.sinemcelik/reels/' rel="noreferrer" target={'_blank'} className='video-play-button'>
                        <ion-icon name="play-circle-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Video