import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import SpotImage from '../../images/spot/spot.png'

function Spot() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                image={SpotImage}
                title={t("SpotFirstTitle")}
                colorTitle={t("SpotSecondTitle")}
                pone={t("SpotFirstP")}
                ptwo={t("SpotSecondP")}
            />
            <Footer />
        </div>
    )
}

export default Spot