import './FooterStyle.css'
import SinemCelikFooter from '../../images/footer/footer-sinem.png'
import { useTranslation } from 'react-i18next'

function Footer() {
    const { t, i18n } = useTranslation()
    return (
        <div className='footer-outside'>
            <div className='container-fluid zero footer-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-5 first-footer-box'>
                            <img src={SinemCelikFooter} className="img img-responsive" alt="sinem celik" />
                            <h5 className='footer-about-title'>{i18n.language === "tr" ? "Uzm." : null} Dr. SİNEM <span className='footer-second-title'>ÇELİK</span></h5>
                            <p className='footer-about-text'>
                                {t("FooterAbout")}
                            </p>
                            <h6 className='footer-contact-info'>{t("FooterContactTel")}</h6>
                            <p className='footer-about-text'>
                                +90 535 845 94 00
                            </p>
                        </div>
                        <div className='col-xs-12 col-sm-push-1 col-sm-6 second-footer-box'>
                            <div className='col-xs-6 col-sm-6 zero'>
                                <h6 className='footer-menu-title'>
                                    {t("FooterMenuTitle")}
                                </h6>
                                <ul className='footer-list zero'>
                                    <li>
                                        <a href='#/'>{t("FooterHomeTitle")}</a>
                                    </li>
                                    {/* <li>
                                        <a href='#/'>{t("FooterServicesTitle")}</a>
                                    </li> */}
                                    {/* {i18n.language === "tr" ?
                                        <li>
                                            <a href='#/'>Blog</a>
                                        </li>
                                        : null
                                    } */}
                                    <li>
                                        <a href='#/'>{t("FooterContactTitle")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xs-6 col-sm-6 zero'>
                                <h6 className='footer-menu-title'>
                                    {t("FooterFollowTitle")}
                                </h6>
                                <ul className='footer-list zero'>
                                    <li>
                                        <a href='https://www.instagram.com/dr.sinemcelik/'>{t("FooterInstagram")}</a>
                                    </li>
                                    <li>
                                        <a href='https://www.facebook.com/profile.php?id=100091074639391'>{t("FooterFacebook")}</a>
                                    </li>

                                    {/* <li>
                                        <a href='#/'>{t("FooterLinkedin")}</a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className='col-xs-12 col-sm-12 zero footer-last-section'>
                                <h6 className='footer-last-title'>{t("FooterWorkHoursTitle")}</h6>
                                <p>
                                    {t("FooterWorkingHoursContent")} <br className='hidden-xs' /> {t("FooterWorkingHoursContent2")} <br /> <br />
                                    {t("FooterMonFriday")} <br />
                                    {t("FooterSatSunday")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid last-item-footer'>
                <div className='row'>
                    <div className='col-xs-12'>
                        © Copyright 2023. All rights reserved <br className='hidden-sm hidden-md hidden-lg' /> by Dr. Sinem Çelik.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer