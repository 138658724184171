import React from 'react'
import "./SliderStyle.css"
import Slider1 from '../../images/slider.png'
import Slider2 from '../../images/slider-1.png'
import { useTranslation } from 'react-i18next'

function Slider() {
    const { t } = useTranslation()
    return (
        <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">

            <ol className="carousel-indicators">
                <li data-target="#carousel-example-generic" data-slide-to="0" className="just-space active"></li>
                <li data-target="#carousel-example-generic" data-slide-to="1" className="just-space"></li>
                <li data-target="#carousel-example-generic" data-slide-to="2" className="just-space"></li>
            </ol>


            <div className="carousel-inner" role="listbox">
                <div className="item active">
                    <img className='img img-responsive' width={"100%"} src={Slider1} alt="slider one" />

                    <div className="carousel-caption">
                        <span className='slider-top-title'>{t("SliderFirstTitle").toUpperCase()}</span>
                        <h3 className='slider-title'>{t("SliderFirstBigTitle")} <span className='second-title'>{t("SliderFirstBigTitle2")}</span></h3>
                        <div className='slider-content'>
                            {t("SliderFirstContent")}
                        </div>
                        <a href='tel:+905358459400' className='btn btn-button slider-button'>{t("SliderFirstButton").toUpperCase()}</a>
                    </div>
                </div>
                <div className="item">
                    <a href='#/'>
                        <img className='img img-responsive' width={"100%"} src={Slider2} alt="slider one" />
                    </a>

                    <div className="carousel-caption">
                        <span className='slider-top-title'>{t("Slider2FirstTitle").toUpperCase()}</span>
                        <h3 className='slider-title'>{t("Slider2FirstBigTitle")} <span className='second-title'>{t("Slider2FirstBigTitle2")}</span></h3>
                        <div className='slider-content'>
                            {t("Slider2FirstContent")}
                        </div>
                        <a href='tel:+905358459400' onClick={() => "tel:+905358459400"} className='btn btn-button slider-button'>{t("SliderFirstButton").toUpperCase()}</a>
                    </div>
                </div>
            </div>

            {/* <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a> */}
        </div>
    )
}

export default Slider