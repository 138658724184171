import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import VitaminImage from '../../images/vitamin/vitamin.png'

function Vitamin() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                title={t("VitaminFirstTitle")}
                colorTitle={t("VitaminSecondTitle")}
                image={VitaminImage}
                pone={t("VitaminFirstP")}
                ptwo={t("VitaminSecondP")}
            />
            <Footer />
        </div>
    )
}

export default Vitamin