import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import HairImage from '../../images/hair/hair.png'

function HairLoss() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                image={HairImage}
                title={t("HairFirstTitle")}
                colorTitle={t("HairSecondTitle")}
                pone={t("HairFirstP")}
                ptwo={t("HairSecondP")}
            />
            <Footer />
        </div>
    )
}

export default HairLoss