import { Routes, Route } from "react-router-dom"
import Botox from '../pages/botox/Botox'
import ContactPage from '../pages/contactPage/ContactPage'
import Dermaterapi from "../pages/dermaterapi/Dermaterapi"
import FaceLift from "../pages/faceLift/FaceLift"
import Filling from "../pages/filling/Filling"
import HairLoss from "../pages/hairLoss/HairLoss"
import Home from '../pages/home/Home'
import Ozone from "../pages/ozon/Ozon"
import Spot from "../pages/spot/Spot"
import Vitamin from "../pages/vitamin/Vitamin"

function Navigation() {
    return (
        <Routes forceRefresh={true}>
            <Route path="/" element={<Home />} />
            <Route path="/botox" element={<Botox />} />
            <Route path="/filling" element={<Filling />} />
            <Route path="/ozon" element={<Ozone />} />
            <Route path="/vitamin" element={<Vitamin />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/dermaterapy" element={<Dermaterapi />} />
            <Route path="/sac-dokulme" element={<HairLoss />} />
            <Route path="/leke-tedavisi" element={<Spot />} />
            <Route path="/face-lift" element={<FaceLift />} />
        </Routes>
    )
}

export default Navigation