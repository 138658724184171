import i18n from "i18next";
import English from './en.json'
import Turkish from './tr.json'
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const myLng = localStorage.getItem('lng')

i18n
    .use(initReactI18next).use(LanguageDetector)
    .init({
        resources: {
            en: English,
            tr: Turkish,
        },
        lng: myLng,
        fallbackLng: "en",
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: true
        },
    })

export default i18n;