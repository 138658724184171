import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import DermaImage from '../../images/dermaterapi/dermaterapi.png'

function Dermaterapi() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                image={DermaImage}
                title={t("DermaTerapyFirstTitle")}
                colorTitle={t("DermaTerapySecondTitle")}
                pone={t("DermaTerapyFirstP")}
                ptwo={t("DermaterapySecondP")}
            />
            <Footer />
        </div>
    )
}

export default Dermaterapi