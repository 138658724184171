import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import FaceImage from '../../images/face/face.png'

function FaceLift() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                image={FaceImage}
                title={t("FaceFirstTitle")}
                colorTitle={t("FaceSecondTitle")}
                pone={t("FaceFirstP")}
                ptwo={t("FaceSecondP")}
            />
            <Footer />
        </div>
    )
}

export default FaceLift