import './AboutStyle.css'
import SinemCelikImage from '../../images/sinemcelik/sinem-celik.png'
import { useTranslation } from 'react-i18next'

function About() {
    const { t } = useTranslation()
    return (
        <div className='container about-container'>
            <div className='row'>
                <div className='hidden-xs hidden-sm about-image-box'>
                    <img className='img img-responsive' width={'100%'} src={SinemCelikImage} alt="uzman doctor sinem celik" />
                    <div className='about-content-text'>
                        <h1 className='about-title'>Uzm. Dr. Sinem <span className='about-lastname'>Çelik</span></h1>
                        <p className='about-content'>
                            {t("HomeAboutFirst")}
                        </p>
                    </div>
                </div>
                <div className='hidden-md hidden-lg col-md-12'>
                    <h1 className='about-title-second'>Uzm. Dr. Sinem <span className='about-lastname'>Çelik</span></h1>
                    <img className='img img-responsive' width={'100%'} src={SinemCelikImage} alt="uzman doctor sinem celik" />
                    <p className='about-content-second'>
                        {t("HomeAboutFirst")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About