import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import BotoxImage from '../../images/botox/botox-details.png'

function Botox() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                title={t("BotoxPageFirstTitle")}
                colorTitle={t("BotoxPageSecondTitle")}
                image={BotoxImage}
                pone={t("BotoxFirstP")}
                ptwo={t("BotoxSecondP")}
            />
            <Footer />
        </div>
    )
}

export default Botox