import React from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContent from '../../components/details-content/DetailsContent'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import OzonImage from '../../images/ozon/ozon.png'

function Ozone() {
    const { t } = useTranslation()
    return (
        <div>
            <Header />
            <DetailsContent
                title={t("OzoneTitle")}
                // colorTitle={t("BotoxPageSecondTitle")}
                image={OzonImage}
                pone={t("OzoneFirstP")}
                ptwo={t("OzoneSecondP")}
            />
            <Footer />
        </div>
    )
}

export default Ozone