import './DetailsStyle.css'
import { Mobile, Whatsapp, Instagram, Location, MenuBoard, SmsEdit } from 'iconsax-react'
import { useTranslation } from 'react-i18next'

function DetailsContent({ title, colorTitle, pone, ptwo, contact, image }) {
    const { t } = useTranslation()
    return (
        <div>
            <div className='container-fluid zero'>
                <div className='row zero'>
                    <div className='col-xs-12 col-sm-12 zero'>
                        {
                            contact ?
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12051.353025718196!2d29.0745837!3d40.963122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7b52376feb3%3A0x27983a56cd7cb439!2sDr.%20Sinem%20%C3%87elik!5e0!3m2!1sen!2str!4v1682963268560!5m2!1sen!2str" key={"key"} title="google maps" width="100%" height="600" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                :
                                <img className='img img-responsive' width={"100%"} src={image} alt="botox cover" />
                        }
                    </div>
                </div>
            </div>
            {/* Content zone start here */}
            <div className='container'>
                <div className='row'>
                    <div className='col-xs-12 col-sm-4'>
                        <h1 className='details-title'>{title}<br />{colorTitle ? <span className='second-color-title'>{colorTitle}</span> : null}</h1>
                        {contact ? null
                            :
                            <div>
                                <div className='hidden-xs genaral-n'>
                                    {t("GeneralTakeAAppointment")}
                                </div>
                                <div className='details-button-box hidden-xs'>
                                    <a href='https://api.whatsapp.com/send?phone=905358459400' className='btn btn-default details-randevu-button'>{t("ContactAppointment")}</a>
                                </div>
                            </div>
                        }
                    </div>
                    {contact ?
                        <div className='col-xs-12 col-sm-8 content-text-box'>
                            <div className='col-xs-12 col-sm-6'>
                                <div className='col-xs-2 col-sm-1 small-contact-box'>
                                    <Location
                                        size="18"
                                        color="#4EB7F2"
                                        style={{ marginRight: 14 }}
                                    />
                                </div>
                                <div className='col-xs-10 col-sm-10'>
                                    <h3 className='contact-sub-title'>{t("ContactAddressTitle")}</h3>
                                    <p>
                                        Suadiye, Noter Sk. No:21 D:3, 34740 <br className='hidden-xs' /> Kadıköy/İstanbul
                                    </p>
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-6'>
                                <div className='col-xs-2 col-sm-1 small-contact-box'>
                                    <MenuBoard
                                        size="18"
                                        color="#EAAE94"
                                        style={{ marginRight: 14 }}
                                    />
                                </div>
                                <div className='col-xs-10 col-sm-10'>
                                    <h3 className='contact-sub-title'>{t("ContactAppointment")}</h3>
                                    <p>
                                        +90 535 845 94 00 <br /> <br></br>
                                    </p>
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-6'>
                                <div className='col-xs-2 col-sm-1 small-contact-box'>
                                    <Whatsapp
                                        size="18"
                                        color="#05D7A0"
                                        style={{ marginRight: 14 }}
                                    />
                                </div>
                                <div className='col-xs-10 col-sm-10'>
                                    <h3 className='contact-sub-title'>Whatsapp</h3>
                                    <p>
                                        +90 535 845 94 00
                                    </p>
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-6'>
                                <div className='col-xs-2 col-sm-1 small-contact-box'>
                                    <SmsEdit
                                        size="18"
                                        color="#000000"
                                        style={{ marginRight: 14 }}
                                    />
                                </div>
                                <div className='col-xs-10 col-sm-10'>
                                    <h3 className='contact-sub-title'>Email</h3>
                                    <p>
                                        info@sinemcelik.com
                                    </p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-xs-12 col-sm-8 content-text-box'>
                            <div className='col-xs-12 col-sm-12'>
                                <p>{pone}</p>
                            </div>
                            <div className='col-xs-12 col-sm-12'>
                                <h4 className='details-second-title'>{t("GeneralSecondaryTitle")}</h4>
                                <p>{ptwo} </p>
                            </div>
                            <div className='col-xs-12 col-sm-12'>
                                <h4 className='details-second-title'>{t("GeneralQuestionFirstTitle")} <span className='second-and-second-title'>{t("GeneralQuestionSecondTitle")}</span></h4>
                                <p>
                                    {t("GeneralAskContent")}
                                </p>
                                <br />
                                <div>
                                    <div className='col-xs-12 col-sm-4 zero'>
                                        <a href='tel:+905358459400' className='btn btn-default last-and-last-button'>
                                            <Mobile
                                                size="18"
                                                color="#4EB7F2"
                                                style={{ marginRight: 14 }}
                                            />
                                            {t("GeneralCallNow")}
                                        </a>
                                    </div>
                                    <div className='col-xs-12 col-sm-4 zero'>
                                        <a href='https://api.whatsapp.com/send?phone=905358459400' className='btn btn-default last-and-last-button'>
                                            <Whatsapp
                                                size="18"
                                                color="#05D7A0"
                                                style={{ marginRight: 14 }}
                                            />
                                            {t("GeneralAskViaWhatsapp")}
                                        </a>
                                    </div>
                                    <div className='col-xs-12 col-sm-4 zero'>
                                        <a href='https://www.instagram.com/dr.sinemcelik/' className='btn btn-default last-and-last-button'>
                                            <Instagram
                                                size="18"
                                                color="#DC0A32"
                                                style={{ marginRight: 14 }}
                                            />
                                            {t("GeneralAskInstagram")}
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default DetailsContent