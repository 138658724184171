import { useTranslation } from 'react-i18next'
import './ContactStyle.css'

function Contact() {
    const { t } = useTranslation()
    return (
        <div className='container contact-container'>
            <div className='row'>
                <div className='col-xs-12 col-sm-12'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12051.353025718196!2d29.0745837!3d40.963122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7b52376feb3%3A0x27983a56cd7cb439!2sDr.%20Sinem%20%C3%87elik!5e0!3m2!1sen!2str!4v1682963268560!5m2!1sen!2str" title='ourLocation' width="100%" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div>
                    <div className='col-xs-12 col-sm-3 media-wrapper'>
                        <div className="media">
                            <div className="media-left">
                                <a href="#/">
                                    <ion-icon name="location"></ion-icon>
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading just-media-title">{t("HomeOurAddress")}</h4>
                                <p>Suadiye, Noter Sk. No:21 D:3, 34740 Kadıköy/İstanbul</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xs-12 col-sm-push-1 col-sm-3 media-wrapper'>
                        <div className="media">
                            <div className="media-left">
                                <a href="#/">
                                    <ion-icon name="document-text"></ion-icon>
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading just-media-title">{t("HomeTakeAppointment")}</h4>
                                <p>+90 535 845 94 00 </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xs-12 col-sm-push-1 col-sm-3 media-wrapper'>
                        <div className="media">
                            <div className="media-left">
                                <a href="#/">
                                    <ion-icon name="mail"></ion-icon>
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading just-media-title">{t("HomeEmailAddress")}</h4>
                                <p>info@drsinemcelik.com </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}





export default Contact